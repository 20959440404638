module.exports = {
  colors: {
    purple: '#6045AF',
    green: '#6DA512',
    gray: '#454550',
    orange: '#EC6E16',
    yellow: '#F3B700',
    white: '#FFFFFF',
    ghostWhite: '#f9f9f9',
    raisinBlack: '#202020',
    philippineGray: '#8b8b92',
    venetianRed: '#f44336',
  },
};
